import ThemeProvider from 'src/theme';
import Router from "src/routes/sections";
import { AuthProvider } from './context/auth';
import { SettingsProvider } from './components/settings';
import { SnackbarProvider } from './components/snackbar';

import './global.css';
import { JobsProvider } from './context/jobs';
import { ScrollProvider } from './context/scroll';

function App() {
  return (
    <AuthProvider>
      <SettingsProvider
        defaultSettings={{
          themeLayout: 'vertical',
        }}
      >
        <ThemeProvider>
          <ScrollProvider>
            <SnackbarProvider>
              <JobsProvider>
                <Router />
              </JobsProvider>
            </SnackbarProvider>
          </ScrollProvider>
        </ThemeProvider>
      </SettingsProvider>
    </AuthProvider>
  );
}

export default App;
