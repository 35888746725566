import {
  Link,
  Stack,
  AppBar,
  Button,
  Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Logo from 'src/components/logo';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { RouterLink } from 'src/routes/components';
import { useResponsive } from 'src/hooks/use-responsive';

import HeaderMenu from './header-menu';
import { HEADER } from '../config-layout';
import AccountPopover from './account-popover';

export default function Header() {
  const theme = useTheme();
  const router = useRouter();
  const mdup = useResponsive('up', 'md');
  const { authenticated, user } = useAuthContext();

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Logo
          sx={{
            width: {
              md: "20rem",
              sm: "14rem",
              xs: "12rem",
            },
          }}
        />
        {
          mdup ? <>
            <Stack direction="row" alignItems="center" spacing={3}>
              {
                authenticated ?
                <Link color="inherit" to={`${user?.role ? paths.admin.dashboard : paths.dashboard.root}`} underline="hover" component={RouterLink}>
                  Dashboard
                </Link> : null
              }
              {
                authenticated ? null :
                  <Link color="inherit" to={paths.jobList} underline="hover" component={RouterLink}>
                    Job Listings
                  </Link>
              }
              <Link color="inherit" to={paths.about} underline="hover" component={RouterLink}>
                About
              </Link>
              <Link color="inherit" to={paths.howItWorks} underline="hover" component={RouterLink}>
                How It Works
              </Link>
              <Link color="inherit" to={paths.guidelines} underline="hover" component={RouterLink}>
                Community Guidelines
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              {
                authenticated ? <AccountPopover /> :
                <>
                  <Button variant="outlined" onClick={() => {
                    router.push(paths.auth.signUp);
                  }}>
                    Join Now
                  </Button>
                  
                  <Button variant="contained" onClick={() => router.push(paths.auth.login)}>Log in</Button>
                </>
              }
            </Stack>
          </>
          :
          <Stack direction="row" alignItems="center">
            {
              authenticated ? null :
                <Button variant="contained" onClick={() => router.push(paths.auth.login)}>Log in</Button>
            }
            <HeaderMenu />
          </Stack>
        }
      </Toolbar>
    </AppBar>
  );
}
