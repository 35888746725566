import moment from "moment";

const singleOrPlural = (val) => {
  if (val === 1) return '';
  else return 's';
}

export const timeDiffFromNow = (timestamp) => {
  const diffInHours = moment().diff(moment.unix(timestamp), "hours");
  if (diffInHours < 1) return `Just now`;
  if (diffInHours < 24) return `${diffInHours} hour${singleOrPlural(diffInHours)} ago`;
  const diffInDays = moment().diff(moment.unix(timestamp), "days");
  if (diffInDays < 7) return `${diffInDays} day${singleOrPlural(diffInDays)} ago`;
  const diffInWeeks = moment().diff(moment.unix(timestamp), "weeks");
  if (diffInWeeks < 4) return `${diffInWeeks} week${singleOrPlural(diffInWeeks)} ago`;
  const diffInMonths = moment().diff(moment.unix(timestamp), "months");
  if (diffInWeeks < 12) return `${diffInMonths} month${singleOrPlural(diffInMonths)} ago`;
  const diffInYears = moment().diff(moment.unix(timestamp), "years");
  return `${diffInYears} year${singleOrPlural(diffInYears)} ago`;
}
