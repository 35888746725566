import { useMemo } from 'react';
import { Box } from '@mui/material';

import { paths } from 'src/routes/paths';

import {
  SavedIconImg,
  ProfileIconImg,
  DashboardIconImg,
} from '../assets';


// ----------------------------------------------------------------------

const icon = (name) => (
  <Box component="img" src={name} />
);

const ICONS = {
  savedJobs: icon(SavedIconImg),
  profile: icon(ProfileIconImg),
  dashboard: icon(DashboardIconImg),
  notifications: icon('clarity:bell-line'),
  feedback: icon('codicon:feedback'),
  jobList: icon('clarity:list-line'),
  communityInsights: icon('ei:chart'),
  hotjar: icon('streamline:cursor-click'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: 'dashboard',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: 'saved jobs',
            path: paths.dashboard.savedJobs,
            icon: ICONS.savedJobs,
          },
          {
            title: 'my profile',
            path: paths.dashboard.myProfile,
            icon: ICONS.profile,
          },
        ],
      },
    ],
    []
  );

  return data;
}
