import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { GuestGuard } from "src/auth/guard";
import AuthLayout from "src/layouts/auth";
import CompactLayout from "src/layouts/compact";
import { paths } from "../paths";
import PageLoader from "src/layouts/common/page-loader";

const LoginPage = lazy(() => import('src/pages/auth/login'));
const SignUpPage = lazy(() => import('src/pages/auth/sign-up'));
const VerifyEmailPage = lazy(() => import('src/pages/auth/verify-email'));
const CreateProfilePage = lazy(() => import('src/pages/auth/create-profile'));

const auth = {
  path: 'auth',
  element: (
    <Suspense fallback={<PageLoader />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    { path: 'verify-email/:token', element: (<VerifyEmailPage />) },
    { path: paths.auth.login, element: (<GuestGuard><AuthLayout><LoginPage /></AuthLayout></GuestGuard>) },
    { path: paths.auth.signUp, element: (<GuestGuard><AuthLayout><SignUpPage /></AuthLayout></GuestGuard>) },
    { path: paths.auth.createProfile, element: <GuestGuard><CompactLayout><CreateProfilePage /></CompactLayout></GuestGuard> },
  ],
}

export const authRoutes = [
  {
    path: '/',
    children: [auth],
  }
];
