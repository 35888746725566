import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { MotionContainer } from 'src/components/animate';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, children, sx }) {
  const { user } = useAuthContext();

  const currentRole = user?.role;

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Permission Denied
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          You do not have permission to access this page
        </Typography>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
