import {
  Box,
  Stack,
} from "@mui/material";
import { useResponsive } from "src/hooks/use-responsive";

export default function AuthLayout({children}) {
  const lgUp = useResponsive('up', 'lg');
  return (
    <Stack
      gap="1rem"
      direction="row"
      minHeight="100vh"
    >
      {
        lgUp ? 
          <Box
            component="img"
            src="/auth-img.png"
          /> : null
      }
      {children}
    </Stack>
  );
}
