import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <Iconify icon={`${name}`} color="primary.main" />
);

const ICONS = {
  users: icon('lucide:users-round'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      {
        items: [
          {
            title: 'users',
            path: paths.admin.dashboard,
            icon: ICONS.users,
          },
        ],
      },
    ],
    []
  );

  return data;
}
