import { useContext } from 'react';

import { JobsContext } from './jobs-context';

export const useJobsContext = () => {
  const context = useContext(JobsContext);

  if (!context) throw new Error('useJobsContext context must be use inside JobsProvider');

  return context;
};
