import {
  lazy,
  Suspense,
} from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { paths } from '../paths';
import PageLoader from 'src/layouts/common/page-loader';

const IndexPage = lazy(() => import('src/pages/dashboard'));
const ProfilePage = lazy(() => import('src/pages/dashboard/profile'));
const JobDetailPage = lazy(() => import('src/pages/dashboard/job-detail'));
const SavedJobsPage = lazy(() => import('src/pages/dashboard/saved-jobs'));
const SavedJobDetailPage = lazy(() => import('src/pages/dashboard/saved-job-detail'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <Suspense fallback={<PageLoader />}>
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        </Suspense>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: paths.dashboard.myProfile, element: <ProfilePage /> },
      { path: paths.dashboard.jobDetail, element: <JobDetailPage /> },
      { path: paths.dashboard.savedJobs, element: <SavedJobsPage /> },
      { path: paths.dashboard.savedJobDetail, element: <SavedJobDetailPage /> },
    ],
  }
];
