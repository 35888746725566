import {
  lazy,
  Suspense,
} from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import CompactLayout from 'src/layouts/compact';

import { paths } from '../paths';
import PageLoader from 'src/layouts/common/page-loader';

const Page404 = lazy(() => import('src/pages/404'));

const FaqPage = lazy(() => import('src/pages/faq'));
// const BlogsPage = lazy(() => import('src/pages/blogs'));
const TosPage = lazy(() => import('src/pages/tos'));
const AboutPage = lazy(() => import('src/pages/about'));
const JobListPage = lazy(() => import('src/pages/job-list'));
const ContactUsPage = lazy(() => import('src/pages/contact-us'));
const JobDetailPage = lazy(() => import('src/pages/job-detail'));
const GuidelinesPage = lazy(() => import('src/pages/guidelines'));
const HowItWorksPage = lazy(() => import('src/pages/how-it-works'));
const PrivacyPolicyPage = lazy(() => import('src/pages/privacy-policy'));
const RepolevedPage = lazy(() => import('src/pages/admin/repoleved'));

export const mainRoutes = [
  {
    element: (
      <Suspense fallback={<PageLoader />}>
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      </Suspense>
    ),
    children: [
      { path: '404', element: <Page404 /> },
      { path: paths.faq, element: <FaqPage /> },
      { path: paths.tos, element: <TosPage /> },
      // { path: paths.blogs, element: <BlogsPage />},
      { path: paths.about, element: <AboutPage /> },
      { path: paths.jobList, element: <JobListPage /> },
      { path: paths.contactUs, element: <ContactUsPage /> },
      { path: paths.jobDetail, element: <JobDetailPage /> },
      { path: paths.guidelines, element: <GuidelinesPage />},
      { path: paths.howItWorks, element: <HowItWorksPage /> },
      { path: paths.privacyPolicy, element: <PrivacyPolicyPage /> },
      { path: paths.repoleved, element: <RepolevedPage /> },
    ],
  }
];
