import { forwardRef } from 'react';
import {
  Box,
  Link,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

const Logo = forwardRef(({ disabledLink = false, variant = 'primary', sx, ...other }, ref) => {
  const logo = (
    <Box
      component="img"
      src={ variant === 'primary' ? "/logo-full.png" : "/logo-full-white.png" }
      sx={{
        width: '20rem',
        cursor: 'pointer',
        aspectRatio: '2936/441',
        ...sx,
      }}
      {...other}
    />
  );
  if (disabledLink) {
    return logo;
  }
  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
})

export default Logo;
