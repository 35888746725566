import {
  useMemo,
  useReducer,
  useCallback,
} from "react";

import http from "src/services/http";
import { JobsContext } from "./jobs-context";
import {
  FETCH_JOBS,
  INITIALIZE,
  FETCH_SAVED_JOBS,
  SET_SINGLE_STATE,
  DENOTE_JOB_AS_SAVED,
} from "./types";

const initialState = {
  page: 1,
  jobs: [],
  geoId: '',
  keyword: '',
  jobTypes: [],
  companyId: '',
  expLevels: [],
  savedJobs: [],
  totalCount: 0,
  datePosted: 'Anytime',
  currentJob: null,
  workplaceTypes: [],
  totalCountSavedJobs: 0,
  abortController: new AbortController(),
};

const reducer = (state, action) => {
  if (action.type === FETCH_JOBS) {
    return {
      ...state,
      jobs: action.payload.jobs,
      totalCount: action.payload.totalCount,
    }
  }

  if (action.type === INITIALIZE) {
    return initialState;
  }

  if (action.type === DENOTE_JOB_AS_SAVED) {
    return {
      ...state,
      jobs: action.payload.jobs,
    }
  }

  if (action.type === FETCH_SAVED_JOBS) {
    return {
      ...state,
      savedJobs: action.payload.savedJobs,
      totalCountSavedJobs: action.payload.totalCountSavedJobs,
    }
  }

  if (action.type === SET_SINGLE_STATE) {
    return {
      ...state,
      [action.payload.key]: action.payload.value,
    }
  }

  return state;
}

export function JobsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchJobs = useCallback(async ({ countPerPage, mode }) => {
    state.abortController.abort();
    const newController = new AbortController();
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value: newController,
        key: 'abortController',
      },
    });
    const { data } = await http.post(mode === 'blind' ? `/jobs/search-blind` : `/jobs/search`, {
      countPerPage,
      page: state.page,
      geoId: state.geoId,
      keyword: state.keyword,
      jobTypes: state.jobTypes,
      companyId: state.companyId,
      expLevels: state.expLevels,
      datePosted: state.datePosted,
      workplaceTypes: state.workplaceTypes,
    }, {signal: newController.signal});
    if (data.status) {
      console.log(data.data)
      dispatch({
        type: FETCH_JOBS,
        payload: {
          jobs: data.data,
          totalCount: data.total,
        }
      });
    } else {
      dispatch({
        type: INITIALIZE,
      });
      console.log(`${data.msg}====>`, data.err);
      throw data.err;
    }
  }, [
    state.page,
    state.geoId,
    state.keyword,
    state.jobTypes,
    state.expLevels,
    state.companyId,
    state.datePosted,
    state.workplaceTypes,
    state.abortController,
  ]);

  const saveJob = useCallback(async (job) => {
    const { data } = await http.post(`/jobs/save-job`, { job });
    if (data.status) {
      const updatedJobs = state.jobs.map((_job) => {
        if (_job.postingId === job.postingId) {
          return {
            ..._job,
            isSaved: true,
          }
        }
        return _job;
      });
      dispatch({
        type: DENOTE_JOB_AS_SAVED,
        payload: {
          jobs: updatedJobs,
        },
      });
    } else {
      console.log(data.err)
    }
  }, [state.jobs]);

  const fetchSavedJobs = useCallback(async () => {
    const { data } = await http.get(`/jobs/saved`);
    if (data.status) {
      dispatch({
        type: FETCH_SAVED_JOBS,
        payload: {
          savedJobs: data.data,
          totalCountSavedJobs: data.total,
        }
      });
    } else {
      dispatch({
        type: INITIALIZE,
      });
      console.log(`${data.msg}====>`, data.err);
    }
  }, []);

  const setKeyword = useCallback((value) => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value,
        key: 'keyword',
      }
    });
  }, []);

  const setDatePosted = useCallback((value) => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value,
        key: 'datePosted',
      },
    });
  }, []);

  const setJobTypesByGroup = useCallback((value) => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value,
        key: 'jobTypes',
      }
    });
  }, []);

  const setExpLevelsByGroup = useCallback((value) => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value,
        key: 'expLevels',
      }
    });
  }, []);

  const setWorkplaceTypesByGroup = useCallback((value) => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value,
        key: 'workplaceTypes',
      }
    });
  }, []);

  const setCompanyId = useCallback(value => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value,
        key: 'companyId',
      },
    });
  }, []);

  const setGeoId = useCallback(value => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value,
        key: 'geoId',
      },
    });
  }, []);

  const setPage = useCallback(value => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value,
        key: 'page',
      }
    });
  }, []);

  const initializeJobs = useCallback(() => {
    dispatch({
      type: INITIALIZE,
    });
  }, []);

  const shareFeedback = useCallback(async ({ feedback, currentJob }) => {
    const { data } = await http.post(`/jobs/share-feedback`, {
      job: currentJob,
      feedback,
    });
    
    return data;
  }, []);

  const fetchFeedbacks = useCallback(async (jobId) => {
    const { data } = await http.post(`/jobs/fetch-feedbacks`, {
      jobId,
    });
    return data;
  }, []);

  const fetchCompanies = useCallback(async (search) => {
    state.abortController.abort();
    const newController = new AbortController();
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value: newController,
        key: 'abortController',
      },
    });
    const { data } = await http.post(`/jobs/fetch-companies`, {
      search,
    }, {signal: newController.signal});
    return data;
  }, [state.abortController]);

  const fetchLocations = useCallback(async (search) => {
    state.abortController.abort();
    const newController = new AbortController();
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value: newController,
        key: 'abortController',
      },
    });
    const { data } = await http.post(`/jobs/fetch-locations`, {
      search,
    }, {signal: newController.signal});
    return data;
  }, [state.abortController]);

  const setCurrentJob = useCallback((job) => {
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value: job,
        key: 'currentJob',
      }
    });
  }, []);

  const fetchCurrentJob = useCallback(async (currentJobId) => {
    state.abortController.abort();
    const newController = new AbortController();
    dispatch({
      type: SET_SINGLE_STATE,
      payload: {
        value: newController,
        key: 'abortController',
      },
    });
    const { data } = await http.post(`/jobs/fetch-current-job`, {
      currentJobId,
    });

    if (data.status) {
      dispatch({
        type: SET_SINGLE_STATE,
        payload: {
          value: data.data,
          key: 'currentJob',
        }
      });
    } else {
      return data;
    }
  }, [state.abortController]);

  const fetchNotifications = useCallback(async () => {
    const { data } = await http.get(`/jobs/fetch-notifications`);
    return data;
  }, []);

  const markAsRead = useCallback(async (notificationId) => {
    const { data } = await http.post(`/jobs/mark-notification-as-read`, { notificationId });
    return data;
  }, []);

  const memoizedValue = useMemo(() => ({
    jobs: state.jobs,
    page: state.page,
    keyword: state.keyword,
    jobTypes: state.jobTypes,
    expLevels: state.expLevels,
    savedJobs: state.savedJobs,
    currentJob: state.currentJob,
    datePosted: state.datePosted,
    totalCount: state.totalCount,
    workplaceTypes: state.workplaceTypes,
    totalCountSavedJobs: state.totalCountSavedJobs,
    saveJob,
    setPage,
    setGeoId,
    fetchJobs,
    markAsRead,
    setKeyword,
    setCompanyId,
    setCurrentJob,
    setDatePosted,
    shareFeedback,
    fetchFeedbacks,
    fetchCompanies,
    fetchLocations,
    fetchSavedJobs,
    initializeJobs,
    fetchCurrentJob,
    setJobTypesByGroup,
    setExpLevelsByGroup,
    fetchNotifications,
    setWorkplaceTypesByGroup,
  }), [
    state.jobs,
    state.page,
    state.keyword,
    state.jobTypes,
    state.expLevels,
    state.savedJobs,
    state.currentJob,
    state.datePosted,
    state.totalCount,
    state.workplaceTypes,
    state.totalCountSavedJobs,
    saveJob,
    setPage,
    setGeoId,
    fetchJobs,
    markAsRead,
    setKeyword,
    setCompanyId,
    setCurrentJob,
    setDatePosted,
    shareFeedback,
    fetchFeedbacks,
    fetchCompanies,
    fetchLocations,
    fetchSavedJobs,
    initializeJobs,
    fetchCurrentJob,
    setJobTypesByGroup,
    setExpLevelsByGroup,
    fetchNotifications,
    setWorkplaceTypesByGroup,
  ]);

  return <JobsContext.Provider value={memoizedValue}>{children}</JobsContext.Provider>
}
