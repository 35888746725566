import { forwardRef } from 'react';
import {
  Box,
  Link,
} from '@mui/material';

import { RouterLink } from 'src/routes/components';

const LogoShorten = forwardRef(({ disabledLink = false, variant = 'primary', sx }, ref) => {
  const logo = (
    <Box
      component="img"
      src={ variant === 'primary' ? "/android-chrome-512x512.png" : "/logo-shorten-white.png" }
      sx={{
        width: "2.4rem",
        cursor: 'pointer',
        ...sx
      }}
    />
  );
  if (disabledLink) {
    return logo;
  }
  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
})

export default LogoShorten;
