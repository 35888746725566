import { Box, Stack } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { hideScroll } from "src/theme/css";
import LogoShorten from "src/components/logo-shorten";
import { NavSectionMini } from "src/components/nav-section";

import { NAV } from "../config-layout";
import { useNavData } from "./config-navigation";
import NavToggleButton from "../common/nav-toggle-button";

export default function NavMini() {
  const theme = useTheme();

  const navData = useNavData();

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          ...hideScroll.x,
        }}
      >
        <LogoShorten sx={{ mx: 'auto', my: 2 }} variant='white' />

        <NavSectionMini
          data={navData}
        />
      </Stack>
    </Box>
  );
}
