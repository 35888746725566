import { useEffect, useState } from 'react';

import { m } from 'framer-motion';
import {
  Box,
  List,
  Badge,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import moment from 'moment';

import Iconify from 'src/components/iconify';
import { useRouter } from 'src/routes/hooks';
import { varHover } from 'src/components/animate';
import { timeDiffFromNow } from 'src/utils/handle-time';
import { useJobsContext } from 'src/context/jobs/use-jobs-context';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

export default function NotificationPopover() {
  const { fetchNotifications: _fetchNotifications, markAsRead } = useJobsContext();
  const [notifications, setNotifications] = useState([]);
  const [unreadNum, setUnreadNum] = useState(notifications.length);
  const popover = usePopover();
  const theme = useTheme();
  const router = useRouter();

  useEffect(() => {
    const fetchNotifications = async () => {
      const data = await _fetchNotifications();
      if (data.status) {
        setNotifications(data.notifications);
      } else {
        console.log(data.err);
      }
    }

    fetchNotifications();
  }, []);

  useEffect(() => {
    setUnreadNum(notifications.filter(notification => (notification.isRead === false)).length);
  }, [notifications]);

  const updateNotificationAsRead = (notificationId) => {
    const updatedNotifications = notifications.map(notification => {
      if (notification._id === notificationId) {
        return {
          ...notification,
          isRead: true,
        }
      }
      return notification;
    });
    setNotifications(updatedNotifications);
  }

  const handleClick = async (jobId, notificationId) => {
    popover.onClose();
    router.push(`/dashboard/job-detail/${jobId}`);
    const data = await markAsRead(notificationId);
    if (data.status) {
      updateNotificationAsRead(notificationId);
    } else {
      console.log(data.err);
    }
  }

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        disabled={!unreadNum}
      >
        <Badge badgeContent={unreadNum ?? ''} color="primary" sx={{
          '& .MuiBadge-badge': {
            color: 'white',
          }
        }}>
          <Iconify icon="clarity:bell-line" width={24} />
        </Badge>
      </IconButton>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 400, p: 0 }}>

        <Box sx={{ p: 2, pb: 1.5, maxHeight: "20rem", overflow: 'auto' }}>
          <List>
            {
              notifications.map((notification) => (
                notification.isRead ? null :
                  <ListItemButton
                    key={notification.jobId}
                    sx={{
                      border: `1px dotted ${theme.palette.grey[300]}`
                    }}
                    onClick={() => handleClick(notification.jobId, notification._id)}
                  >
                    <ListItemIcon>
                      <Iconify icon="mdi:information" />
                    </ListItemIcon>
                    <ListItemText primary={
                      <Box component="div">
                        <Box component="span" sx={{ color: 'primary.dark', fontWeight: 'bold' }}>{notification.username}</Box> left a feedback about <Box component="span" sx={{ color: 'primary.main' }}>{notification.jobTitle}</Box> {timeDiffFromNow(moment(notification.createdAt).unix())}
                      </Box>
                    }
                    />
                  </ListItemButton>
              ))
            }
          </List>
        </Box>
      </CustomPopover>
    </>
  );
}
